import React from "react";
import { Link } from "gatsby";
import Button from "@components/Button";

const NotFoundPage = () => {
  return (
    <>
      <p>...we can't find the page you're looking for</p>
      <Button as={Link} to="/">
        Return home
      </Button>
    </>
  );
};

export default NotFoundPage;
